import React, { useMemo } from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { EXPERIMENTS, VIEWER_HOOKS } from '../../../../../constants';
import { alignmentName } from '../../../../../utils/alignment';
import settingsParams, { ButtonStyle } from '../../../settingsParams';
import stylesParams from '../../../stylesParams';
import { Cta } from './Cta';
import { Footer } from './Footer';
import { FreeTrial } from './FreeTrial';
import { st, classes, vars } from './index.st.css';
import { PlanName } from './PlanName';
import { Price } from './Price';
import { Recurrence } from './Recurrence';
import { Ribbon } from './Ribbon';
import { Tagline } from './Tagline';
import { Validity } from './Validity';

export interface PlanProps {
  plan: PublicPlan;
  recurringPlansExist: boolean;
  freeTrialDaysExist: boolean;
  taglinesExist: boolean;
  validityCyclesExist: boolean;
  highlighted: boolean;
  anyBenefits?: boolean;
  expandMobileBenefits: boolean;
  onClick: () => void;
}

export const Plan: React.FC<PlanProps> = ({
  plan,
  recurringPlansExist,
  freeTrialDaysExist,
  validityCyclesExist,
  taglinesExist,
  highlighted,
  onClick,
  anyBenefits,
  expandMobileBenefits,
}) => {
  const settings = useSettings();
  const styles = useStyles();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const shadowStyle = useShadowStyle(highlighted);
  const hasTwoDetails = validityCyclesExist && freeTrialDaysExist;
  const blendBgColors = !settings.get(settingsParams.wereNewSettingsOpened);
  const areAdditionalButtonSettingsEnabled = experiments.enabled(EXPERIMENTS.ADDITIONAL_BUTTON_SETTINGS_VIEWER);
  const descriptionAlignment = alignmentName(styles.get(stylesParams.descriptionAlignment));
  const buttonAlignment = alignmentName(styles.get(buttonAlignmentStylesParam(styles.get(stylesParams.buttonStyle))));
  const showPlanTagline = taglinesExist && styles.get(stylesParams.showTagline);

  return (
    <div
      className={st(classes.planWrapper, {
        showShadow: shadowStyle.show,
        highlighted,
      })}
      style={{ [vars.shadowXOffset]: shadowStyle.xOffset + 'px', [vars.shadowYOffset]: shadowStyle.xOffset + 'px' }}
    >
      <Ribbon highlighted={highlighted} />
      <div
        className={st(classes.plan, {
          highlighted,
          blendBgColors,
        })}
      >
        <div className={st(classes.planTop, { textAlignment: descriptionAlignment })}>
          <PlanName highlighted={highlighted} name={plan.name || ''} />
          <Price highlighted={highlighted} price={plan.pricing?.price} />
          <Recurrence highlighted={highlighted} pricing={plan.pricing} recurringPlansExist={recurringPlansExist} />
          {showPlanTagline && (
            <Tagline planId={plan.id ?? ''} highlighted={highlighted} description={plan.description || ''} />
          )}
          <div className={st(classes.duration, { hasTwoDetails, mobile: isMobile })}>
            <Validity highlighted={highlighted} plan={plan} />
            <FreeTrial highlighted={highlighted} plan={plan} freeTrialDaysExist={freeTrialDaysExist} />
          </div>
          <div
            data-hook={VIEWER_HOOKS.PLAN_CTA_WRAPPER}
            className={st(classes.ctaWrapper, {
              buttonAlignmentEnabled: areAdditionalButtonSettingsEnabled,
              buttonAlignment,
            })}
          >
            <Cta plan={plan} highlighted={highlighted} onClick={onClick} />
          </div>
        </div>
        <Footer
          benefits={plan.perks?.values ?? []}
          anyBenefits={anyBenefits}
          highlighted={highlighted}
          expandMobileBenefits={expandMobileBenefits}
        />
      </div>
    </div>
  );
};

const buttonAlignmentStylesParam = (value: ButtonStyle) => {
  return buttonAlignmentStylesParamMap[value];
};

const buttonAlignmentStylesParamMap = {
  [ButtonStyle.EMPTY_CORNERED]: stylesParams.buttonEmptyCorneredAlignment,
  [ButtonStyle.EMPTY_ROUNDED]: stylesParams.buttonEmptyRoundedAlignment,
  [ButtonStyle.FULL_ROUNDED]: stylesParams.buttonFullRoundedAlignment,
  [ButtonStyle.FULL_CORNERED]: stylesParams.buttonFullCorneredAlignment,
};

export const useShadowStyle = (highlighted: boolean) => {
  const settings = useSettings();
  const styles = useStyles();

  return useMemo(() => {
    const showShadowParam = highlighted ? 'showHighlightedPlanCardShadow' : 'showRegularPlanCardShadow';
    const shadowDistanceParam = highlighted ? 'highlightedCardShadowDistance' : 'regularCardShadowDistance';
    const shadowAngleParam = highlighted ? 'highlightedCardShadowAngle' : 'regularCardShadowAngle';
    const shadowDistance = styles.get(stylesParams[shadowDistanceParam]);
    const showShadow = settings.get(settingsParams[showShadowParam]);
    const shadowAngle = styles.get(stylesParams[shadowAngleParam]);
    const shadowXOffset = shadowDistance * Math.sin((Math.PI * 2 * shadowAngle) / 360);
    const shadowYOffset = -shadowDistance * Math.cos((Math.PI * 2 * shadowAngle) / 360);

    return {
      show: showShadow,
      xOffset: shadowXOffset,
      yOffest: shadowYOffset,
    };
  }, [settings, styles, highlighted]);
};
